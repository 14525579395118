<template>
  <div>
    <!-- loading -->
    <div class="mini-cart__spinner" v-if="updatingDashboard">
          <i class="fa fa-spinner fa-spin"></i>
    </div>
    <!-- show prices -->
    <button class="masthead_button masthead_button--cart"
      type="button"
      @click="openMiniCart"
      v-else-if="displayPrices">
      <span class="masthead_button__icon">
        <i class="fa-solid fa-fw fa-shopping-cart"></i>
      </span>
      <span class="masthead_button__label">
        <span class="masthead_button__text masthead_button__text--bold">
          {{ dashboardTotal }}
        </span>
        <span class="masthead_button__hint masthead_button__hint--notbold">
          {{ dboard.showgst }}
        </span>
      </span>
      <!-- cart item count -->
      <div class="masthead_button__count masthead_button__count--warn"
        v-if="showRemoved && displayPrices">
        <i class="fa-solid fa-triangle-exclamation"></i>
      </div>
      <div class="masthead_button__count" v-else-if="dashboardItems && displayPrices">
        {{ dashboardItems }}
      </div>
    </button>
    <!-- no prices -->
    <button class="masthead_button masthead_button--cart"
      type="button"
      @click="openMiniCart"
      v-else>
      <span class="masthead_button__icon">
        <i class="fa-solid fa-fw fa-shopping-cart"></i>
      </span>
      <span class="masthead_button__label">
        <span class="masthead_button__text masthead_button__text--bold">
          {{ dashboardItems || '0' }} items
      </span>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Dashboard_mastheadNew',
  props: {
    dboard: {
      type: Object,
      required: true,
    },
    openMiniCart: {
      type: Function,
      required: true,
    },
    updatingDashboard: {
      type: Boolean,
      required: true,
    },
    displayPrices: {
      type: Function,
      required: true,
    },
    dashboardTotal: String,
    dashboardItems: Number,
    removedItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateMobileDashboard() {
      // update the count on mobile dashboard
      const target = document.getElementById('dashboard_container_mob');
      if (!target) return;
      const num = this.dashboardItems ?
        `<div id="item_count_mob">${this.dashboardItems}</div>`
        : '';
      target.innerHTML = `<div id="cart_mob">
        <a href="/main-orders-viewcurrent" class="cart_image" aria-label="Cart" title="Cart"></a>
        ${num}
      </div>`;
    },
  },
  computed: {
    showNum() {
      return this.dashboardItems > 0;
    },
    showRemoved() {
      return this.removedItems.length > 0;
    },
  },
  updated() {
    this.updateMobileDashboard();
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      this.updateMobileDashboard();
    });
  },
};
</script>
