<template>
  <div class="mini-cart not-minimal">
    <Dashboard
      v-if="dashboardToShow === 'Dashboard'"
      :dboard="dboard"
      :openMiniCart="openMiniCart"
      :updatingDashboard="updatingDashboard"
      :displayPrices="displayPrices"
      :dashboardTotal="dashboardTotal"
      :dashboardItems="dashboardItems"
      :hasItems="dashboardItems"
      :removedItems="filteredRemovedItems"
    />
    <Dashboard_mastheadNew
      v-else
      :dboard="dboard"
      :openMiniCart="openMiniCart"
      :updatingDashboard="updatingDashboard"
      :displayPrices="displayPrices"
      :dashboardTotal="dashboardTotal"
      :dashboardItems="dashboardItems"
      :hasItems="dashboardItems"
      :removedItems="filteredRemovedItems"
    />
    <Drawer
      drawerClass="mini-cart__drawer"
      :drawerBg="drawerBg"
      :drawerStyle="drawerStyle"
      :middleStyle="middleStyle"
      :showDrawer="showMiniCart"
      :closeDrawer="closeMiniCart">
      <div class="mini-cart__content" v-if="updatingMiniCart">
        <div class="mini-cart__empty">
          <div class="mini-cart__spinner-large">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>
      <div class="mini-cart__content" v-else>
        <div class="mini-cart__nav leftright">
          <a href="" class="mini-cart__link" @click="closeMiniCart">
            <i class="fa-solid fa-arrow-left"></i>
            <span>Continue shopping</span>
          </a>
        </div>
        <div class="mini-cart__header">
          <div class="mini-cart__header_row leftright">
            <h2 class="header2-24 ">Your cart</h2>
            <div
              v-if="dashboardItems"
              class="mini-cart__header-text"
              :class="{'mini-cart__updating': updating}">
              {{ dashboardItems }} {{ itemsLabel }} | {{ numDeliveryDates }} {{  datesLabel }}
            </div>
          </div>
          <CartProgress
            v-if="showProgressBar"
            :updating="updating"
            :cartProgress="cartProgress"/>
          <ViewRemoved
            v-if="filteredRemovedItems.length"
            :removedItems="filteredRemovedItems"
            :updateRemoved="updateRemoved" />
          <div v-if="hasSUPItem" class="mini-cart__header-sup">
            <i class="fa-solid fa-triangle-exclamation"></i>
            {{ supHeaderMessage }}
          </div>
        </div>
        <ActiveCart v-if="dashboardItems"
          :loggedOut="loggedOut"
          :loginLightbox="loginLightbox"
          :gotoRegistration="gotoRegistration"
          :dboard="dboard"
          :dashboardTotal="dashboardTotal"
          :gotoCart="gotoCart"
          :hasItems="hasItems"
          :itemsByEdd="itemsByEdd"
          :removeItem="removeItem"
          :updateItem="updateItem"
          :cartMinimumOrder="cartMinimumOrder"
          :itemsUpdating="itemsUpdating"
          :itemsDeleting="itemsDeleting"
          :updating="updating"
          :deleteFailed="deleteFailed"
          :updateFailed="updateFailed"
          :noEddsYet="noEddsYet"
          :supTagMessage="supTagMessage"
        />
        <EmptyCartLoggedOut v-else-if="loggedOut"
          :loginLightbox="loginLightbox"
          :gotoRegistration="gotoRegistration"
        />
        <EmptyCartLoggedIn v-else
          :closeMiniCart="closeMiniCart"
        />
      </div>
    </Drawer>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { doAdobeEvents } from '@/helpers/adobe';
import Drawer from '@/components/common/Drawer.vue';
import Dashboard from '@/components/minicart/Dashboard.vue';
import Dashboard_mastheadNew from '@/components/minicart/Dashboard_mastheadNew.vue';
import ActiveCart from '@/components/minicart/ActiveCart.vue';
import EmptyCartLoggedOut from '@/components/minicart/EmptyCartLoggedOut.vue';
import EmptyCartLoggedIn from '@/components/minicart/EmptyCartLoggedIn.vue';
import CartProgress from '@/components/minicart/CartProgress.vue';
import ViewRemoved from '@/components/minicart/ViewRemoved.vue';

const specialIssues = {
  small_order_surcharge: 1,
  minimum_order_value: 1,
  order_exceeds_budget: 1,
  cp_item_acknowledgement: 1,
  custom_item_acknowledgement: 1,
  incorrect_status_for_mode: 1,
  item_added: 1,
  zone_freight_surcharge: 1,
};

let cartUpdateNumber = 0;
const saveDelay = 3000;

export default {
  name: 'miniCart',
  components: {
    Drawer,
    Dashboard,
    Dashboard_mastheadNew,
    ActiveCart,
    EmptyCartLoggedOut,
    EmptyCartLoggedIn,
    CartProgress,
    ViewRemoved,
  },
  props: {
    dboard: {
      type: Object,
      required: true,
    },
  },
  data: function data() {
    return {
      showMiniCart: false,
      updatingDashboard: false,
      updatingMiniCart: false,
      isGeneric: this.dboard.isgen,
      isAnonymous: this.dboard.isanon,
      dashboardTotal: this.dboard.ordertotal || '$0.00',
      noajaxcart: this.dboard.noajaxcart,
      cartUrl: window.nxDatalayer.env.cart_endpoint,
      cartData: {},
      cartItems: [],
      cartItemsById: {}, // hash of cart items by cartid, can be used to lookup items
      cartIssues: [],
      drawerStyle: {},
      middleStyle: {},
      interstitial: {},
      cartProgress: {},
      cartSnippets: {},
      drawerWidth: 575,
      drawerBg: false,
      hasGotCart: false,
      noEddsYet: true,
      // save data variables
      itemsPendingUpdate: [], // items where a qty change has been sent to the server
      itemsPendingDelete: [], // items where a delete has been sent to the server
      itemsAwaitingUpdate: [], // items where a qty change has not yet been sent to the server
      itemsAwaitingDelete: [], // items where a delete has not yet been sent to the server
      updateTimeout: null, // store the timeout for the next update
      updateFailed: [],
      deleteFailed: [],
      removedItems: [], // items that were removed by the backend, that showed up as an issue
    };
  },
  computed: {
    dashboardToShow() {
      return window.nxDatalayer.global.streams.mastheadNew
        ? 'Dashboard_mastheadNew'
        : 'Dashboard';
    },
    cartHeader() {
      return nxDatalayer?.global?.streams.mini_inter
        ? 'item_quantity_hash,apps,ca,charge_gap,adobe,top_n,u,interstitial,env_message,cart_progress,cart_snippets'
        : 'item_quantity_hash,apps,ca,charge_gap,adobe,top_n,u,env_message,cart_progress,cart_snippets';
    },
    datesLabel() {
      return this.numDeliveryDates > 1 ? 'delivery dates' : 'delivery date';
    },
    itemsLabel() {
      return this.dashboardItems > 1 ? 'items' : 'item';
    },
    dashboardItems() {
      return this.hasGotCart
        ? this.cartItems.length
        : this.dboard.nitems;
    },
    hasCart() {
      // the only thing that might be in cartData is orderid
      return Object.keys(this.cartData).length > 1;
    },
    hasItems() {
      return this.cartItems.length > 0;
    },
    displayPrices() {
      return this.dboard.displayprices > 0;
    },
    loggedOut() {
      return this.isGeneric && this.isAnonymous;
    },
    buildEddUrl() {
      // Return an array of EDD urls, each with 50 items or less
      const urlArr = [];
      const pcodes = this.cartItems.map((item) => item.prodcode);
      // sometimes the order doesn't return a postcode, then we use the global one
      const postcode = this.cartData.postcode || nxDatalayer.global.postcode;
      const eddBaseUrl = `${window.nxDatalayer.global.etd_endpoint}au/${postcode}?`;
      for (let i = 0; i < pcodes.length; i += 50) {
        const batch = pcodes.slice(i, i + 50);
        urlArr.push(batch.reduce((acc, val) => `${acc}&material=${val}`, `${eddBaseUrl}`));
      }
      return urlArr;
    },
    cartMinimumOrder() {
      return this.cartIssues.find((issue) => issue.shortcode === 'E08');
    },
    showProgressBar() {
      return !this.loggedOut && nxDatalayer.global?.displayprices
        && this.cartItems.length > 0;
    },
    supHeaderMessage() {
      let supMessage = this.cartSnippets['SUP-mcart-warn-text']
        ? this.cartSnippets['SUP-mcart-warn-text']
        : 'You have single use plastic item/s in your cart, which legislation prohibits the purchase of in your state.';
      supMessage = supMessage.replace('$state', this.cartData.state);
      return supMessage;
    },
    hasSUPItem() {
      return this.cartItems.some((item) => item.single_use_plastic);
    },
    supTagMessage() {
      const supMessage = this.cartSnippets['SUP-item-warn-tag']
        ? this.cartSnippets['SUP-item-warn-tag']
        : 'Single use plastic item';
      return supMessage;
    },
    itemsByEdd() {
      return this.cartItems.reduce((acc, item) => {
        const edd = item.is_preorder ? '.Preorder' : (item.edd_sort || '.No EDD');
        if (!acc[edd]) {
          acc[edd] = [];
        }
        acc[edd].push(item);
        return acc;
      }, {});
    },
    numDeliveryDates() {
      return Object.keys(this.itemsByEdd).length;
    },
    itemsAwaiting() { // if we need to send something to the server
      return this.itemsAwaitingDelete.length || this.itemsAwaitingUpdate.length;
    },
    itemsPending() { // if we have sent something to the server, and are pending response
      return this.itemsPendingDelete.length || this.itemsPendingUpdate.length;
    },
    updating() {
      return this.itemsPending || this.itemsAwaiting;
    },
    itemsUpdating() {
      return [...this.itemsAwaitingUpdate, ...this.itemsPendingUpdate];
    },
    itemsDeleting() {
      return [...this.itemsAwaitingDelete, ...this.itemsPendingDelete];
    },
    // e.g. {"customerreference":null,
    // "item_update":[{"itemid":"268877981","quantity":2,"action":"update"},
    // {"itemid":"268877982","action":"delete"}]}
    updatePayload() {
      const item_update = this.itemsAwaitingUpdate.map((id) => {
        const item = this.cartItemsById[id];
        return { itemid: id, quantity: item.quantity, action: 'update' };
      });
      const item_delete = this.itemsAwaitingDelete.map((id) => {
        return { itemid: id, action: 'delete' };
      });
      return { item_update: [...item_update, ...item_delete] };
    },
    goToInterstitial() {
      const interstitialCount = sessionStorage.getItem('interstitialCount') || 0;
      return this.interstitial
        && this.interstitial.interstitial_type !== 'none'
        && nxDatalayer?.global?.streams?.mini_inter
        && interstitialCount < this.interstitial.interstitial_max_visits;
    },
    filteredRemovedItems() {
      // only show removedItems for the current order
      return this.removedItems.filter((item) => this.cartData.orderid === item.orderid);
    },
  },
  methods: {
    openMiniCart(evt) {
      // Stop original link click of cart icon
      if (evt) evt.preventDefault();

      if (this.noajaxcart) {
        window.location.href = '/main-orders-viewcurrent';
        return;
      }

      // Display mini cart and slide-in drawer
      this.showMiniCart = true;
      this.slideIn();

      // Hide page elements
      this.displayPageElements(false);

      // Extract cart data if not cached
      if (!this.hasCart) {
        // Display mini cart spinner
        this.updatingMiniCart = true;

        // Get cart using the rest api
        this.getCart()
          .then((response) => {
            // Remove mini cart spinner
            this.updatingMiniCart = false;
          });
      }
      // adobe
      doAdobeEvents({ event148: undefined }, {}, 'Opened mini cart');
    },
    closeMiniCart(evt) {
      // Stop original link click
      evt.preventDefault();

      // Fade drawer background and slide-out drawer
      this.drawerBg = false;
      this.drawerStyle = {
        transition: 'margin 0.5s',
        width: `${this.drawerWidth}px`,
        'margin-right': `-${this.drawerWidth}px`,
      };

      // Allow drawer to slide-out before removal
      setTimeout(() => {
        this.showMiniCart = false;

        // Display page elements
        this.displayPageElements(true);
      }, 500);
    },
    clearMiniCart() {
      this.cartData = {};
      this.cartItems = this.cartItems.splice(0);
      this.dashboardTotal = '$0.00';
    },
    displayPageElements(showElems) {
      // Find page elements
      const btto = document.getElementById('btto');
      const clogo = document.querySelector('.masthead-middle');
      // Toggle display
      if (showElems) {
        if (clogo) { clogo.style.zIndex = '700'; }
        window.nx.chat.showChat(true);
        if (btto) { btto.classList.remove('hide-for-now'); }
        document.body.classList.remove('no_scroll_minicart');
      } else {
        if (clogo) { clogo.style.zIndex = '0'; }
        window.nx.chat.showChat(false);
        if (btto) { btto.classList.add('hide-for-now'); }
        document.body.classList.add('no_scroll_minicart');
      }
    },
    slideIn() {
      setTimeout(() => { this.drawerBg = true; }, 100);
      this.drawerStyle = { 'margin-right': `-${this.drawerWidth}px` };
      this.middleStyle = { width: `${this.drawerWidth}px` };
      setTimeout(() => {
        this.drawerStyle = {
          transition: 'margin 0.5s',
          width: `${this.drawerWidth}px`,
          'margin-right': 0,
        };
      }, 100);
    },
    checkRemoved(issues) {
      // this checks the issues object to record what items were removed from the order
      const storedRemoved = JSON.parse(sessionStorage.getItem('removedItems')) || [];
      Vue.set(this, 'removedItems', storedRemoved);
      const codes = ['W16', 'W17', 'W18', 'W19', 'W20'];
      issues.forEach((issue) => {
        if (codes.includes(issue.shortcode)) {
          // ignore dupes
          if (this.removedItems.find((item) => {
            return item.prodcode === issue.prodcode
              && item.orderid === this.cartData.orderid;
          })) {
            return;
          }
          const desc = issue.message.split('"');
          this.removedItems.push({
            prodcode: issue.prodcode,
            description: desc[1] ? desc[1] : '',
            shortcode: issue.shortcode,
            orderid: this.cartData.orderid,
          });
          // add to sessionStorage
          sessionStorage.setItem('removedItems', JSON.stringify(this.removedItems));
        }
      });
    },
    updateRemoved(prodcode) {
      // this removes an item from the removed list
      const newArr = this.removedItems.filter((item) => item.prodcode !== prodcode);
      Vue.set(this, 'removedItems', newArr);
      sessionStorage.setItem('removedItems', JSON.stringify(newArr));
    },
    getCart() {
      //  make sure we have the latest orderid
      this.cartUrl = window.nxDatalayer.env.cart_endpoint;
      // Try and deduce cartUrl if empty
      const so_ep = window.nxDatalayer.env.saved_orders_endpoint;
      const tr_cart = window.nxDatalayer.tracking.cart;
      if (!this.cartUrl && so_ep && tr_cart) {
        this.cartUrl = `${so_ep}/${tr_cart.orderid}`;
      }
      // Cannot find rest endpoint URL
      if (!this.cartUrl) {
        return Promise.resolve('No Url');
      }
      // Extract cart data
      this.updatingMiniCart = true;
      return axios.get(this.cartUrl,
        { headers: { 'X-NX-Extras': this.cartHeader, 'X-NX-Checkout': 'pre-flight' } })
        .then((response) => {
          // Handle empty cart response
          if (typeof response.data === 'string') {
            this.clearMiniCart();
          } else {
            // Extract cart total and item count
            this.hasGotCart = true;

            // Update dashboard and store cart data
            this.cartData = response.data;
            this.updateCommmonItems(response.data);
            Vue.set(this, 'cartItems', response.data.items);

            // build a hash of cartitems by cartid
            this.cartItemsById = this.cartItems.reduce((acc, item) => {
              acc[item.itemid] = item;
              return acc;
            }, {});

            // Extract EDDs for cart items
            if (this.hasItems) {
              const eddUrls = this.buildEddUrl;
              axios.all(eddUrls.map((l) => axios.get(l)))
                .then((responses) => {
                  // Merge all EDD data into a single object
                  const edds = responses.reduce(
                    (acc, response) => Object.assign(acc, response.data.data), {}
                  );

                  // Process EDDs and update cart items
                  this.processEDDs(edds);
                  this.noEddsYet = false;
                  this.updatingMiniCart = false;
                })
                .catch((error) => {
                  this.updatingMiniCart = false;
                  console.log('EDD Error', error);
                });
            } else {
              this.updatingMiniCart = false;
            }
          }
        })
        .catch((error) => {
          // When a cart is deleted on cart page, clear mini cart
          console.log('error', error);
          if (error.response?.status === 404) {
            this.clearMiniCart();
          }
        });
    },
    processEDDs(edds) {
      const pad = (n) => (n < 10 ? `0${n}` : n);
      // Merge EDD data with cart items
      this.cartItems.forEach((item) => {
        const edd = edds[item.prodcode].date;
        let edd_sort = null;
        if (edd) {
          edd_sort = new Date(edd);
          edd_sort = `${edd_sort.getFullYear()}-${pad(edd_sort.getMonth() + 1)}-${pad(edd_sort.getDate())}`;
        }
        // Add EDD to item. new property, so it's not yet watched
        Vue.set(item, 'edd', edd && item.show_etd ? edd : null);
        Vue.set(item, 'edd_sort', edd_sort && item.show_etd ? edd_sort : null);
      });
    },
    updateMiniCart() {
      // Display dashboard spinner
      this.updatingDashboard = true;

      this.cartUrl = window.nxDatalayer.env.cart_endpoint;
      // Get updated cart using the rest api
      this.getCart()
        .then((response) => {
          // Display dashboard cart
          this.updatingDashboard = false;
        });
    },
    removeItem(id) {
      this.itemsAwaitingDelete.push(id);
      // clear any update failed message
      const hasFail = this.deleteFailed.indexOf(id);
      if (hasFail !== -1) {
        this.deleteFailed = this.deleteFailed.splice(hasFail, 1);
      }
      this.debounceUpdate();
    },
    updateItem(id, newValue) {
      this.itemsAwaitingUpdate.push(id);
      const item = this.cartItemsById[id];
      item.quantity = newValue;
      // clear any update failed message
      const hasFail = this.updateFailed.indexOf(id);
      if (hasFail !== -1) {
        this.updateFailed = this.updateFailed.splice(hasFail, 1);
      }
      this.debounceUpdate();
    },
    debounceUpdate() {
      if (cartUpdateNumber) {
        // ensure pending requests aren't processed, because we'll be sending another shortly
        cartUpdateNumber = 0;
      }
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
      }
      this.updateTimeout = setTimeout(this.sendUpdate, saveDelay);
    },
    sendUpdate() {
      const payload = this.updatePayload;
      cartUpdateNumber = new Date().getTime().toString();
      // send post
      axios.post(this.cartUrl, payload, {
        headers: {
          'X-NX-Checkout': 'pre-flight',
          'X-NX-Extras': this.cartHeader,
          'X-NX-Request-Id': cartUpdateNumber
        }
      })
        .then((response) => {
          // if its not the latest update, ignore
          if (cartUpdateNumber !== response.headers['x-nx-request-id']) {
            return;
          }
          // update items
          this.itemsPendingUpdate.forEach((id) => {
            const item = this.cartItemsById[id];
            response.data.items.forEach((responseItem) => {
              if (responseItem.itemid === id) {
                if (item.quantity !== responseItem.quantity) {
                  this.updateFailed.push(id);
                }
                item.quantity = responseItem.quantity;
                item.price = responseItem.price;
              }
            });
          });
          // delete items
          this.itemsPendingDelete.forEach((id) => {
            if (response.data.items.find((item) => item.itemid === id)) {
              this.deleteFailed.push(id);
            } else {
              delete this.cartItemsById[id];
              for (let i = 0; i < this.cartItems.length; i += 1) {
                if (this.cartItems[i].itemid === id) {
                  this.cartItems.splice(i, 1);
                  break;
                }
              }
            }
          });
          nxDatalayer.tracking.cart.in_cart = response.data.item_quantity_hash;
          window.nx.addToCart.updateInCartStrip();
          this.updateCommmonItems(response.data);
          // doAdobe
          doAdobeEvents({ event149: undefined }, {}, 'Updated mini cart');
          this.itemsPendingUpdate = [];
          this.itemsPendingDelete = [];
        })
        .catch((error) => {
          console.log('Update failed !'); // eslint-disable-line no-console
          console.log(error); // eslint-disable-line no-console
        });
      // move awaiting items to pending
      this.itemsPendingUpdate = [...this.itemsPendingUpdate, ...this.itemsAwaitingUpdate];
      this.itemsAwaitingUpdate = [];
      this.itemsPendingDelete = [...this.itemsPendingDelete, ...this.itemsAwaitingDelete];
      this.itemsAwaitingDelete = [];
    },
    updateCommmonItems(data) {
      // these fields should be updated every time we get server data
      this.dashboardTotal = data.price;
      this.cartIssues = data.issues;
      this.checkRemoved(data.issues);
      Vue.set(this, 'interstitial', data.interstitial);
      Vue.set(this, 'cartProgress', data.cart_progress);
      Vue.set(this, 'cartSnippets', data.cart_snippets);
    },
    loginLightbox() {
      if (window.nx?.login?.login_lightbox) {
        nx.login.login_lightbox();
      } else {
        window.location.href = '/main-my-login';
      }
    },
    filterIssues(issues, type) {
      return issues.reduce((prev, curr) =>
        curr.type === type && !specialIssues[curr.code]
          ? [...prev, curr]
          : prev, []);
    },
    gotoRegistration() {
      window.location.href = '/main-my-reg';
    },
    gotoCart() {
      if (this.goToInterstitial) {
        // increment interstitial count
        const iCount = sessionStorage.getItem('interstitialCount');
        sessionStorage.setItem('interstitialCount', iCount ? parseInt(iCount, 10) + 1 : 1);
        // go to interstitial
        window.location.href = '/main-orders-interstitial?from=minicart';
      } else {
        // go to cart
        window.location.href = '/main-orders-viewcurrent';
      }
    },
  },
  mounted() {
    // check sessionStorage for removed items
    const storedRemoved = JSON.parse(sessionStorage.getItem('removedItems')) || [];
    Vue.set(this, 'removedItems', storedRemoved);
    this.cartData.orderid = nxDatalayer?.tracking?.cart?.orderid;
    // Override existing update cart functionality
    window.nx.addToCart.updateCart = this.updateMiniCart;
    window.nx.addToCart.openMiniCart = this.openMiniCart;
  },
};
</script>
